import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function PaymentComplete() {

    const { transactionId } = useParams();

    return (
        <div>
            <h1>Thank you for your payment - {transactionId}</h1>
        </div>
    );
}

export default PaymentComplete;
