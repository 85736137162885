import React, { useEffect, useState } from 'react';
import HealthblocksApi from './AxiosConfig';
import { Link } from 'react-router-dom';

const DisplayProgressMessage = (props) => {
    const progressMessage = props.progressMessage;
    useEffect(() => {
    }, []);

    return (
        progressMessage
            ?
            <div className="rounded-md bg-green-50 p-4">
                <div className="flex">
                    <div className="shrink-0">
                    </div>
                    <div className="ml-3">
                        {progressMessage}
                    </div>
                    <div className="ml-auto pl-3">
                        <div className="-mx-1.5 -my-1.5">
                            <button
                                type="button"
                                onClick={() => progressMessage = []}
                                className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                            >
                                <span className="sr-only">Dismiss</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div></div>
    );
};

export default DisplayProgressMessage;