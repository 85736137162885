import React, { useState } from 'react';
import HealthblocksApi from './AxiosConfig';

const AddSponsorPrizeButton = (props) => {

    var activityPool = props.activityPool;
    var setParentProgressMessage = props.setProgressMessages;
    const [progressMsg, setProgressMsg] = useState();

    const addProgressMessage = (msg) => {
        if (setParentProgressMessage) setParentProgressMessage(msg);
        else setProgressMsg(msg);
    }

    const addPrize = async () => {
        try {

            if (!activityPool) {
                setProgressMsg('No activityPoolId supplied to add sponsor prize to');
            }
            else {
                try {
                    //Need to go through payment to add sponsor prize
                    var paymentRequest = {
                        "product": {
                            "type": "ACTIVITY_POOL_FUNDING",
                            "activityPoolId": activityPool.activityPoolId
                        },
                        "returnUrl": `https://everest.healthblocks.ai/paymentcomplete`
                    };
                    addProgressMessage('Requesting payment ... please wait');
                    var response = await HealthblocksApi.post('/payment-api/transactions/', paymentRequest);
                    var makePaymentUrl = response.data.redirect_url;
                    addProgressMessage(`Redirecting to payment (${makePaymentUrl})... please wait`);
                    window.location.href = makePaymentUrl;
                }
                catch (err) {
                    addProgressMessage(`Error Requesting Payment ${JSON.stringify(err.message)}`);
                }

            }

        }
        catch (err) {
            setProgressMsg(`Unexpected error adding sponsored prize to activity pool - ${err.message}`);
        }
    };

    return (
        activityPool.activityPoolStatus === "REVIEW" || activityPool.activityPoolStatus === "DRAFT"
            ?
            <section>
                <div class="sm:col-span-12 bg-white text-black px-2 items-right">
                    <button type="button" onClick={() => addPrize()} class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Add Sponsor Prize</button>
                </div>
                <div class="sm:col-span-12 bg-white text-black px-2 items-right">
                    {
                        progressMsg
                            ?
                            <div>
                                <span class="block text-xs font-medium leading-6 text-gray-400">{progressMsg}</span>
                            </div >
                            :
                            <span></span>
                    }
                </div>

            </section >
            :
            <section>Unable to add a sponsor prize</section>


    );
};

export default AddSponsorPrizeButton;