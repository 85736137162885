import React, { useEffect, useState } from 'react';
import HealthblocksApi from './AxiosConfig';
import moment from 'moment';

const HealthDataList = (props) => {
    const [healthdata, setHealthData] = useState([]);

    const userId = props.userId;

    const fetchHealthData = async () => {
        const today = moment().format('YYYY-MM-DD');
        const dateMinus7Days = moment().subtract(7, 'days').format('YYYY-MM-DD');
        var apiUrl = `https://api.healthblocks.ai/activity-pool-api/data/${userId}?start_date=${dateMinus7Days}&end_date=${today}&activity_type=STEPS&aggregate_by_month=false&aggregate_by_year=false`
        const response = await HealthblocksApi.get(apiUrl);
        var results = [];
        var weekNum = 0;
        response.data[0].weeks.forEach(week => {
            results = results.concat(week[weekNum++]);
        });
        results = results.sort((a, b) => new Date(b.date) - new Date(a.date));
        setHealthData(results);
    };

    var useEffectHasRun = false;
    useEffect(() => {
        if (!useEffectHasRun) {
            useEffectHasRun = true;
            fetchHealthData();
        }
    }, []);

    return (
        <div class="px-4 sm:px-6 lg:px-8 mt-4">
            <div class="sm:flex sm:items-center">
                <div class="sm:flex-auto">
                    <h1 class="text-base font-semibold leading-6 text-gray-900">{healthdata.length} Health Records</h1>
                </div>
            </div>
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr class="divide-x divide-gray-200">
                                    <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">Day</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Value</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Date</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                {healthdata
                                    .map(healthDataRecord => (
                                        <tr class="divide-x divide-gray-200">
                                            <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">{healthDataRecord.tag}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{healthDataRecord.value}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{healthDataRecord.date}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HealthDataList;