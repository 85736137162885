import React, { useEffect, useState } from 'react';
import HealthblocksApi from './AxiosConfig';
import { Link } from 'react-router-dom';

const DataPoolList = (props) => {
    const [dataPools, setDataPools] = useState([]);

    const dpSponsor = props.sponsor;

    const fetchdataPools = async () => {
        const apiUrl = process.env.REACT_APP_DATA_PLATFORM_API_URL;

        const response = await HealthblocksApi.get(`${apiUrl}/sponsored_data_pools/${dpSponsor.sponsorId}`);
        setDataPools(response.data);
    };

    const deletedataPools = async (id) => {
        const apiUrl = process.env.REACT_APP_DATA_PLATFORM_API_URL;
        await HealthblocksApi.delete(`${apiUrl}/data_pools/${id}`);
        fetchdataPools();
    };

    useEffect(() => {
        fetchdataPools();
    }, []);

    return (
        <div class="px-4 sm:px-6 lg:px-8 mt-4">
            <div class="sm:flex sm:items-center">
                <div class="sm:flex-auto">
                    <h1 class="text-base font-semibold leading-6 text-gray-900">{dataPools.length} Data Pools {dpSponsor ? "For " + dpSponsor.name : ""}</h1>
                </div>
                <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <Link to={`/dataPools/new${dpSponsor ? "/" + dpSponsor.sponsorId : ""}`} class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" >{`New ${dpSponsor ? "Sponsored" : ""} Data Pool`}</Link>
                </div>
            </div>
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr class="divide-x divide-gray-200">
                                    <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                                        <span class="sr-only">Edit</span>
                                    </th>
                                    <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">Status</th>
                                    <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">Title</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Reward</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                {dataPools
                                    .map(dataPool => (
                                        <tr key={dataPool.dataPoolId} class="divide-x divide-gray-200">
                                            <td class="relative whitespace-nowrap border-b border-gray-200 py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-2 lg:pr-2">
                                                <Link class="text-indigo-600 hover:text-indigo-900" to={`/dataPools/${dataPool.dataPoolId}`}>Edit</Link>
                                            </td>
                                            <td class="whitespace-nowrap p-1 text-sm text-gray-500">{dataPool.dataPoolStatus}</td>
                                            <td class="whitespace-nowrap p-1 text-sm text-gray-500">{dataPool.title}</td>
                                            <td class="whitespace-nowrap p-1 text-sm text-gray-500">{dataPool.reward + ' ' + dataPool.currencyCode}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataPoolList;