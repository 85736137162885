import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cloudinary } from '@cloudinary/url-gen/index';
import { fill } from "@cloudinary/url-gen/actions/resize";
import UploadWidget from './UploadWidget';
import { color } from '@cloudinary/url-gen/qualifiers/background';


const ImageHolder = (props) => {
    // Create a Cloudinary instance and set your cloud name.
    const cld = new Cloudinary({
        cloud: {
            cloudName: 'healthblocks'
        }
    });

    // Instantiate a CloudinaryImage object for the image with the public ID, 'docs/models'.
    const myImage = cld.image('activitypools/outdooradventurebackpacker');

    // Resize to 250 x 250 pixels using the 'fill' crop mode.
    myImage.resize(fill().width(250).height(250));

    useEffect(() => {

    }, []);

    const setImageUrl = (url) => {
        console.log("ImageHolder set image Url " + url);
        props.setimageurl(url);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
    };

    return (
        <div>
            <h1 class="mb-2 font-bold">{props.imagetitle}</h1>
            <div>
                {props.imageurl && props.imageurl.length > 0
                    ?
                    <div class="relative w-[355px]">
                        <img class="mb-2 w-[355px] h-[450px] h-full object-cover" src={props.imageurl} alt={props.imagetitle} ></img>
                        <div class="absolute inset-0 flex items-center justify-left p-4">
                            <span class={`text-4xl font-bold w-[220px]`} style={{color: props.imagetextcolor}} >{props.imagetext}</span>
                        </div>
                    </div>
                    :
                    <></>
                }
                <UploadWidget setImageUrl={setImageUrl} />
                {props.imageurl && props.imageurl.length > 0
                    ?
                    <h3 class="mt-2 text-xs">Image Url: {props.imageurl}</h3>
                    :
                    <></>
                }
            </div>
        </div>

    );
};

export default ImageHolder;