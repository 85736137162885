import React, { useEffect, useState } from 'react';
import HealthblocksApi from './AxiosConfig';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ImageHolder from './ImageHolder';
import ColorPicker from './ColorPicker';
import { usePrivy, getAccessToken } from '@privy-io/react-auth';
//import dataPoolRegistrations from './dataPoolRegistrations';
//import dataPoolRewardsList from './dataPoolRewards';
//import SponsorPrizeList from './SponsorPrizeList';
import DisplayErrorMessages from './DisplayErrorMessages';
import DisplayProgressMessage from './DisplayProgressMessage';
//import dataPoolStatusButton from './dataPoolStateButton';
import moment, { duration } from 'moment/moment';
import { HexColorPicker } from 'react-colorful';
import { v4 as uuidv4 } from 'uuid';

const DataPoolForm = () => {
    const navigate = useNavigate();
    const { dataPoolId, sponsorId } = useParams();
    const [sexSettings, setSexSettings] = useState([]);
    const [genderSettings, setGenderSettings] = useState([]);
    const [countries, setCountries] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const { user } = usePrivy();
    const [tabIndex, setTabIndex] = useState(0);
    const { hash } = window.location
    const [showForm, setShowForm] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [progressMessage, setProgressMessage] = useState();

    const addErrorMessage = (newError) => {
        setErrorMessages([...errorMessages, newError])
    };

    var tomorrowStartOfDay = moment().add(1, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss');
    var dayAfterTomorrowStartOfDay = moment().add(2, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss');

    const [dataPool, setDataPool] = useState({
        dataPoolId: '',
        title: '',
        description: '',
        dataPoolStatus: 'DRAFT',
        sponsorId: sponsorId ? sponsorId : "",
        mainColor: '',
        secondaryColor: '',
        backgroundImage: '',
        listImage: '',
        iconImage: '',
        instructions: '',
        currencyCode: '',
        reward: 0,
        publicVisibleStartDateTime: tomorrowStartOfDay,
        entriesOpenStartDateTime: tomorrowStartOfDay,
        featuredStartDateTime: dayAfterTomorrowStartOfDay,
        featuredEndDateTime: moment.add,
        startDateTime: tomorrowStartOfDay,
        minimumAge: '0',
        minParticipants: '0',
        maxParticipants: '1000',
        countryCodeRestriction: '',
        zipCodeRestriction: '',
        townRestriction: '',
        sexRestriction: '',
        genderRestriction: ''
    });

    useEffect(() => {
        if (dataPoolId) {
            const fetchdataPool = async () => {
                setProgressMessage("Fetching Data Pool");
                try {
                    const apiUrl = process.env.REACT_APP_DATA_PLATFORM_API_URL;
                    const response = await HealthblocksApi.get(`${apiUrl}/data_pools/${dataPoolId}`);
                    var dp = response.data;
                    setDataPool(dp);
                    if (dp.dataPoolStatus === "OPEN" || dp.dataPoolStatus === "ACTIVE" || dp.dataPoolStatus === "COMPLETED" || dp.dataPoolStatus === "FINISHED") {
                        setReadOnly(true);
                    }
                    setShowForm(true);
                    setProgressMessage(null);
                }
                catch (err) {
                    addErrorMessage(err.message);
                }

            };
            fetchdataPool();
            if (hash == "dataPools") setTabIndex(1);
        }
        else {
            setShowForm(true);
        }
        fetchSettings();
    }, [dataPoolId]);

    useEffect(() => {
        if (hash == "#registrations") setTabIndex(1);
        else if (hash == "#rewards") setTabIndex(2);
        else setTabIndex(0);
    }, [hash]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDataPool({ ...dataPool, [name]: value });
    };

    const setDataPoolStatus = (state) => {
        setDataPool({ ...dataPool, dataPoolStatus: state });
    }

    const deletedataPool = async (dataPoolId) => {
        const apiUrl = process.env.REACT_APP_DATA_PLATFORM_API_URL;
        await HealthblocksApi.delete(`${apiUrl}/data_pools/${dataPoolId}`);
        navigate(-1);
    }

    const fetchSettings = async () => {
        try {
            const currencyResponse = await HealthblocksApi.get(`currency-api/currency/`);
            setCurrencies(currencyResponse.data.data);
            const sexResponse = await HealthblocksApi.get(`user-profile-api/metadata/biological-sex`);
            setSexSettings(sexResponse.data);
            const genderResponse = await HealthblocksApi.get(`user-profile-api/metadata/genders`);
            setGenderSettings(genderResponse.data);
            //TODO SET COUNTRIES
        }
        catch (err) {
            addErrorMessage(err.message);
        }

    }

    const setAPMainImageUrl = (url) => {
        setDataPool({ ...dataPool, backgroundImage: url });
    }

    const setIconImageUrl = (url) => {
        setDataPool({ ...dataPool, iconImage: url });
    }

    const setMainColor = (color) => {
        setDataPool({ ...dataPool, mainColor: color });
    }

    const setSecondaryColor = (color) => {
        setDataPool({ ...dataPool, secondaryColor: color });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessages([]);
        try {
            var response;
            const apiUrl = process.env.REACT_APP_DATA_PLATFORM_API_URL;
            if (dataPoolId) {
                setProgressMessage("Updating ...");

                response = await HealthblocksApi.put(`${apiUrl}/data_pools/`, dataPool);
            } else {
                setProgressMessage("Creating New ...");
                var newId = uuidv4();
                dataPool.dataPoolId = newId;
                response = await HealthblocksApi.post(`${apiUrl}/data_pools/`, dataPool);
            }
            setProgressMessage("Save Complete");
            navigate(-1);
        }
        catch (err) {
            addErrorMessage(err.message);
            if (err.response && err.response.data) {
                addErrorMessage(JSON.stringify(err.response.data));
            }
        }
    };

    return (
        !showForm
            ?
            <section>Please Wait ...</section>
            :
            <div>
                <div>
                    <div class="sm:hidden">
                        <label for="tabs" class="sr-only">Select a tab</label>
                        <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                            <option>Data Pool Details</option>
                            <option>Registrations</option>
                            <option>Rewards</option>
                        </select>
                    </div>
                    <div class="hidden sm:block">
                        <div class="border-b border-gray-200">
                            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                                <Link onClick={() => setTabIndex(0)} to="#dataPooldetails" class={tabIndex === 0 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Data Pool Details</Link>
                                <Link onClick={() => setTabIndex(1)} to="#registrations" class={tabIndex === 1 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Registrations</Link>
                                <Link onClick={() => setTabIndex(2)} to="#rewards" class={tabIndex === 2 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Rewards</Link>
                            </nav>
                        </div>
                    </div>
                </div>

                <section id="content">
                    {tabIndex === 0
                        ?
                        <form onSubmit={handleSubmit} class="mt-4">
                            <div class="space-y-12">
                                <div class="border-b border-gray-900/10 pb-12">
                                    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                        <div class="sm:col-span-6 bg-black text-white px-2">
                                            <h2 class="text-base font-semibold leading-7">Data Pool - ({dataPool.dataPoolId === "" ? 'New' : dataPool.dataPoolId})</h2>
                                        </div>

                                        {
                                            dataPool.sponsorId !== ''
                                                ?
                                                <div class="sm:col-span-6 bg-black text-white px-2">
                                                    <h2 class="text-base font-semibold leading-7 text-white-900">Sponsored By {dataPool.sponsorId}</h2>
                                                </div>
                                                :
                                                ''
                                        }


                                        <div class="sm:col-span-3">
                                            <label for="dataPoolStatus" class="block text-sm font-medium leading-6 text-gray-900">Status</label>
                                            <div class="mt-2">
                                                {/*Manually Set by Admins - DRAFT, REVIEW - For Review, PUBLISHED - Viewable by the public*/}
                                                {/*Automatically Set OPEN - Open for entries, ACTIVE - In-Progress, FINISHED - Data Pool Ended, COMPLETED - Rewards Paid Out */}
                                                <span class="mr-4">{dataPool.dataPoolStatus}</span><dataPoolStatusButton dataPool={dataPool} setDataPoolStatus={setDataPoolStatus} />
                                            </div>
                                        </div>

                                        <div class="sm:col-span-3">
                                            <label for="title" class="block text-sm font-medium leading-6 text-gray-900">Title</label>
                                            <div class="mt-2">
                                                <input type="text" name="title" value={dataPool.title} onChange={handleChange} placeholder="title" required id="title" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-6">
                                            <label for="description" class="block text-sm font-medium leading-6 text-gray-900">Description</label>
                                            <div class="mt-2">
                                                <textarea type="text" rows="5" name="description" value={dataPool.description} onChange={handleChange} placeholder="description" required id="description" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-6">

                                        </div>

                                        <div class="sm:col-span-6">
                                        </div>

                                        <div class="sm:col-span-1">
                                            <label for="minParticipants" class="block text-sm font-medium leading-6 text-gray-900">Min Participants</label>
                                            <div class="mt-2">
                                                <input type="text" name="minParticipants" value={dataPool.minParticipants} onChange={handleChange} placeholder="Min Participants" required id="minParticipants" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-1">
                                            <label for="maxParticipants" class="block text-sm font-medium leading-6 text-gray-900">Max Participants</label>
                                            <div class="mt-2">
                                                <input type="text" name="maxParticipants" value={dataPool.maxParticipants} onChange={handleChange} placeholder="Max Participants" required id="maxParticipants" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-6 bg-black text-white px-2">
                                            <h2 class="text-base font-semibold leading-7 text-white-900">Visible Dates</h2>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="publicVisibleStartDateTime" class="block text-sm font-medium leading-6 text-gray-900">Publicly Visible</label>
                                            <div class="mt-2">
                                                <input type="datetime-local" name="publicVisibleStartDateTime" value={dataPool.publicVisibleStartDateTime ? dataPool.publicVisibleStartDateTime.substring(0, 16) : ""} onChange={handleChange} placeholder="Public Visible" required id="entriesOpenStartDateTime" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="entriesOpenStartDateTime" class="block text-sm font-medium leading-6 text-gray-900">Entries Open</label>
                                            <div class="mt-2">
                                                <input type="datetime-local" name="entriesOpenStartDateTime" value={dataPool.entriesOpenStartDateTime ? dataPool.entriesOpenStartDateTime.substring(0, 16) : ""} onChange={handleChange} placeholder="Entries Open" required id="entriesOpenStartDateTime" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-6 bg-black text-white px-2">
                                            <h2 class="text-base font-semibold leading-7 text-white-900">Pool Commences</h2>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="startDateTime" class="block text-sm font-medium leading-6 text-gray-900">Start Date</label>
                                            <div class="mt-2">
                                                <input type="datetime-local" name="startDateTime" value={dataPool.startDateTime ? dataPool.startDateTime.substring(0, 16) : ""} onChange={handleChange} placeholder="Start Date" id="startDateTime" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-6 bg-black text-white px-2">
                                            <h2 class="text-base font-semibold leading-7 text-white-900">Feature Data Pool</h2>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="featuredStartDateTime" class="block text-sm font-medium leading-6 text-gray-900">Featured From</label>
                                            <div class="mt-2">
                                                <input type="datetime-local" name="featuredStartDateTime" value={dataPool.featuredStartDateTime ? dataPool.featuredStartDateTime.substring(0, 16) : ""} onChange={handleChange} placeholder="Featured From" id="featuredStartDateTime" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="featuredEndDateTime" class="block text-sm font-medium leading-6 text-gray-900">Featured To</label>
                                            <div class="mt-2">
                                                <input type="datetime-local" name="featuredEndDateTime" value={dataPool.featuredEndDateTime ? dataPool.featuredEndDateTime.substring(0, 16) : ""} onChange={handleChange} placeholder="Featured To" id="featuredEndDateTime" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-6 bg-black text-white px-2">
                                            <h2 class="text-base font-semibold leading-7 text-white-900">Entry Restrictions</h2>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="minimumAge" class="block text-sm font-medium leading-6 text-gray-900">Minimum Age</label>
                                            <div class="mt-2">
                                                <input type="text" name="minimumAge" value={dataPool.minimumAge} onChange={handleChange} placeholder="Minimum Age" id="minimumAge" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="townRestriction" class="block text-sm font-medium leading-6 text-gray-900">Town Restriction</label>
                                            <div class="mt-2">
                                                <input type="text" name="townRestriction" value={dataPool.townRestriction} onChange={handleChange} placeholder="Town Restriction" id="townRestriction" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="zipCodeRestriction" class="block text-sm font-medium leading-6 text-gray-900">Zipcode Restriction</label>
                                            <div class="mt-2">
                                                <input type="text" name="zipCodeRestriction" value={dataPool.zipCodeRestriction} onChange={handleChange} placeholder="Zip/Postcode Restriction" id="zipCodeRestriction" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="sexRestriction" class="block text-sm font-medium leading-6 text-gray-900">Sex Restriction</label>
                                            <div class="mt-2">
                                                <select id="sexRestriction" name="sexRestriction" value={dataPool.sexRestriction} onChange={handleChange} placeholder="Sex Restriction" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                    <option>NONE</option>
                                                    {sexSettings
                                                        .map(sexSetting => (
                                                            <option>{sexSetting}</option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="genderRestriction" class="block text-sm font-medium leading-6 text-gray-900">Gender Restriction</label>
                                            <div class="mt-2">
                                                <select id="genderRestriction" name="genderRestriction" value={dataPool.genderRestriction} onChange={handleChange} placeholder="Gender Restriction" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                    <option>NONE</option>
                                                    {genderSettings
                                                        .map(genderSetting => (
                                                            <option>{genderSetting}</option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="countryCodeRestriction" class="block text-sm font-medium leading-6 text-gray-900">Country Restriction</label>
                                            <div class="mt-2">
                                                <select id="countryCodeRestriction" name="countryCodeRestriction" value={dataPool.countryCodeRestriction} onChange={handleChange} placeholder="Country Restriction" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                    <option>NONE</option>
                                                    {countries
                                                        .map(country => (
                                                            <option>{country}</option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-6 bg-black text-white px-2">
                                            <h2 class="text-base font-semibold leading-7 text-white-900">Colours and Images</h2>
                                        </div>

                                        <div class="sm:col-span-3">
                                            <ImageHolder imagetitle="Data Pool Main Image" setImageUrl={setAPMainImageUrl} imageUrl={dataPool.backgroundImage} />
                                        </div>

                                        <div class="sm:col-span-3">
                                            <ImageHolder imagetitle="Data Pool Icon" setImageUrl={setIconImageUrl} imageUrl={dataPool.iconImage} />
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="mainColor" class="block text-sm font-medium leading-6 text-gray-900">Main Colour</label>
                                            <div class="mt-2">
                                                <input type="text" name="mainColor" value={dataPool.mainColor} onChange={handleChange} placeholder="Main Color" id="mainColor" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                            <div class="mt-2">
                                                <HexColorPicker color={dataPool.mainColor} onChange={setMainColor} />
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="secondaryColor" class="block text-sm font-medium leading-6 text-gray-900">Secondary Colour</label>
                                            <div class="mt-2">
                                                <input type="text" name="secondaryColor" value={dataPool.secondaryColor} onChange={handleChange} placeholder="Secondary Color" id="secondaryColor" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                            <div class="mt-2">
                                                <HexColorPicker color={dataPool.secondaryColor} onChange={setSecondaryColor} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {
                                !readOnly
                                    ?
                                    <div>
                                        <DisplayProgressMessage progressMessage={progressMessage} />
                                        <DisplayErrorMessages errorMessages={errorMessages} />
                                        <div class="mt-6 flex items-center justify-end gap-x-6">
                                            <Link onClick={() => navigate(-1)} class="text-sm font-semibold leading-6 text-gray-900">Cancel</Link>
                                            {
                                                dataPool.dataPoolStatus == "DRAFT" || dataPool.dataPoolStatus == "REVIEW" || dataPool.dataPoolStatus == "PUBLISHED"
                                                    ?
                                                    <button type="button" onClick={() => deletedataPool(dataPool.dataPoolId)} class="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Delete</button>
                                                    :
                                                    <span></span>
                                            }
                                            <button type="submit" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                                        </div>
                                    </div>
                                    :
                                    <div class="mt-6 mb-6 flex items-center justify-end gap-x-6">
                                        <div>Data Pool Read Only Due to Status</div>
                                    </div>
                            }
                        </form>
                        :
                        tabIndex === 1
                            ?
                            <div class="sm:col-span-6 bg-black text-white px-2 mt-8">
                                <h2 class="text-base font-semibold leading-7">Registrations - Coming Soon</h2>
                            </div>
                            :
                            <div class="sm:col-span-6 bg-black text-white px-2 mt-8">
                                <h2 class="text-base font-semibold leading-7">Rewards - Coming Soon</h2>
                            </div>
                    }
                </section>

            </div>
    );
};

export default DataPoolForm;