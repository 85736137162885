import React from 'react';

function NotLoggedIn() {
    return (

    <div class="p-4">
        <div class="mx-auto w-full text-black text-center p-4">
            <h1 class="mt-4 text-6xl lg:text-8xl ">Login</h1>
            <div class="mt-8 w-full p-4">
                <p class="text-2xl lg:text-3xl">Please login to view this page</p>
            </div>
            <div class="flex mt-16 justify-center">
                <img loading="lazy" alt="" src="https://cdn.prod.website-files.com/66abcbc3916804a06659a724/66d1c34c0f2a04e545542eeb_C1_002%202%20(1).avif" class="mt-4 translate: none; rotate: none; scale: none; transform: translate(0px, 0px);"></img>
            </div>
        </div>
    </div>
    );
}

export default NotLoggedIn;
