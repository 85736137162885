import React, { useEffect, useState } from 'react';
import HealthblocksApi from './AxiosConfig';
import { Link } from 'react-router-dom';

const DisplayErrorMessages = (props) => {
    const errorMessages = props.errorMessages;

    var displayErrors = Array.isArray(errorMessages) ? errorMessages : [];

    useEffect(() => {
    }, []);

    return (
        displayErrors.length > 0
            ?
            <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">{displayErrors.length} errors</h3>
                        <div className="mt-2 text-sm text-red-700">
                            <ul role="list" className="list-disc space-y-1 pl-5">
                                {displayErrors.map((error, index) => (<li key={index}>{error}</li>))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div></div>
    );
};

export default DisplayErrorMessages;